/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import Dropzone from "dropzone";
import {
  Row,
  Col,
  Button,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Spinner,
} from "reactstrap";
import apiDriver from "stores/api.driver";
import config from "config/global";

import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import NumericInput from "components/Form/NumericInput";

Dropzone.autoDiscover = false;

function Parcel(props) {
  const { parcel, index, changeParcelData } = props;

  return (
    <Row>
      <Col md="2">{index + 1}</Col>
      <Col md="10">
        <FormGroup>
          <InputGroup>
            <NumericInput
              min={0.001}
              step={0.001}
              id={`parcel${index}Weight`}
              name={`parcel.${index}.weight`}
              placeholder="Waga przesyłki"
              value={parcel.weight}
              onChange={changeParcelData}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>kg</InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
      </Col>
    </Row>
  );
}

function GLSParcels(props) {
  const { data, addParcel, changeParcelData } = props;

  return (
    <>
      <Col md={12}>
        {data.parcels &&
          data.parcels.map((parcel, index) => (
            <Parcel
              key={index}
              index={index}
              parcel={parcel}
              changeParcelData={changeParcelData}
            />
          ))}
      </Col>
      <Col md={12} className="text-right">
        <Button type="button" size="sm" color="success" onClick={addParcel}>
          Dodaj paczkę
        </Button>
      </Col>
    </>
  );
}

export default function GLSForm(props) {
  const { shipping, changeShippingSpecialData, parseValue } = props;

  const [loading, setLoading] = useState(false);

  const data = shipping.data || {};

  useEffect(() => {
    if (shipping.id != null) {
      setLoading(true);
      apiDriver
        .get(config.api.gls + shipping.id + "/GetData")
        .pipe(
          map((response) => {
            return response.response;
          }),
          catchError((error) => {
            setLoading(false);
            return of(error);
          }),
        )
        .subscribe((data) => {
          setLoading(false);
          triggerUpdate(data?.parcels);
        });
    } else {
      if (shipping.orderId != null) {
        const attributes = apiDriver.buildIndexAttributes({
          take: 1000,
          filters: {
            orderId: {
              filterVal: shipping.orderId,
              filterType: "Guid",
              comparator: "=",
              caseSensitive: false,
            },
          },
        });
        apiDriver
          .get(`${config.api.orders}pl/OrderProducts/${attributes}`)
          .subscribe({
            next: (response) => {
              triggerUpdate(
                response.response?.map((r) => ({ weight: r.weight })),
              );
            },
            error: console.log("error"),
          });
      }
    }
  }, []);

  const changeParcelData = (e) => {
    let parcels = data.parcels || [];
    const value = parseValue(e.target);
    const splitted = value.name.split(".");
    parcels = parcels.map((parcel, id) => {
      if (id !== Number(splitted[1])) return parcel;
      return { ...parcel, [splitted[2]]: value.value };
    });
    triggerUpdate(parcels);
  };

  const addParcel = (e) => {
    triggerUpdate([...(data?.parcels || []), { weight: 0.0 }]);
  };

  const triggerUpdate = (parcels) => {
    changeShippingSpecialData({ target: { name: "parcels", value: parcels } });
  };

  return (
    <>
      <Col md={12}>
        <h3>GLS</h3>
      </Col>
      <Col md={12}>Paczki wchodzące w skład przesyłki</Col>
      {loading ? (
        <Col md={12} className="mt-5 mb-3 text-center">
          <Spinner />
        </Col>
      ) : (
        <GLSParcels
          data={data}
          changeParcelData={changeParcelData}
          addParcel={addParcel}
        />
      )}
    </>
  );
}
