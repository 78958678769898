import React from "react";

import localizer from "stores/localizer";
import Dropzone from "dropzone";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Button,
  Table,
  CardSubtitle,
  Label,
  FormGroup,
  Input,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import PaymentModal from "./PaymentModal";
import _ from 'lodash';

import { API_URL as API_PAYMENTS_URL } from "stores/orders/payments/epics";
import apiDriver from "stores/api.driver";
import config from "config/global";

import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import Uploader from "./Uploader";
import ProductPersonalization from "components/Products/ProductPersonalization";

Dropzone.autoDiscover = false;

class OrderProductFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      materials: [],
      projects: [],
      previews: [],
      verification: [],
      product: props.product,
      notes: [],
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    this.loadProduct();
  };

  loadProduct = () => {
    const { product } = this.state;
    apiDriver
      .get(`${config.api.orders}/pl/OrderProducts/${product.id}?Full=true`)
      .subscribe({
        next: (response) => {
          this.setState({ product: response.response });
        },
        error: (error) => {
          console.log(error);
        },
        complete: () => this.loadProjects()
      });
  }

  loadProjects = () => {
    const { product } = this.state;
    apiDriver
      .get(`${config.api.orders}/pl/OrderProducts/${product.id}/Projects`)
      .subscribe({
        next: (response) => {
          if (response.response?.length > 0) {
            this.setState({ projects: response.response });
          }
        },
        error: (error) => {
          console.log(error);
        },
        complete: () => this.loadMaterials()
      });
  }

  loadMaterials = () => {
    const { product } = this.state;
    apiDriver
      .get(`${config.api.orders}/pl/OrderProducts/${product.id}/Materials`)
      .subscribe({
        next: (response) => {
          if (response.response?.length > 0) {
            this.setState({ materials: response.response });
          }
        },
        error: (error) => {
          console.log(error);
        },
        complete: () => this.loadPreviews()
      });
  }

  loadPreviews = () => {
    const { product } = this.state;
    apiDriver
      .get(`${config.api.orders}/pl/OrderProducts/${product.id}/Previews`)
      .subscribe({
        next: (response) => {
          if (response.response?.length > 0) {
            this.setState({ previews: response.response });
          }
        },
        error: (error) => {
          console.log(error);
        },
        complete: () => this.loadNotes()
      });

  }

  loadNotes = () => {
    const { product } = this.state;
    let filters = {};
    filters.productId = {
      filterVal: product.id,
      filterType: "Guid",
      comparator: "=",
      caseSensitive: false,
      filterKey: "productId",
    };
    let attributes = apiDriver.buildIndexAttributes({
      filters: filters,
    });
    let url = `${config.api.orders}pl/OrderNotes${attributes}`;
    apiDriver
      .get(url)
      .subscribe({
        next: (response) => {
          if (response.response?.length > 0) {
            this.setState({ notes: response.response });
          }
        },
        error: (error) => {
          console.log(error);
        },
        complete: () => this.loadProjectPreview()
      });
  }

  loadProjectPreview = () => {
    const { projects, product } = this.state;
    const project = this.lastProject();
    if (project) {
      apiDriver
        .get(
          config.api.orders +
          `pl/OrderProducts/${product.id}/Projects/${project.objectName}/Preview?Take=1`,
        )
        .subscribe({
          next: (response) => {
            if (response && response.response && response.response.length > 0) {
              this.setState({ projectPreview: response.response[0] });
            }
            return response.response;
          },
          error: console.error,
          complete: () => this.loadPreviewPreview()
        })
    }
  }

  loadPreviewPreview = () => {
    const { previews, product } = this.state;
    const preview = previews?.sort((a, b) => a.stat.lastModified > b.stat.lastModified).find(a => true);
    if (preview) {
      apiDriver
        .get(
          config.api.orders +
          `pl/OrderProducts/${product.id}/Previews/${preview.objectName}/Preview?Take=1`,
        )
        .subscribe({
          next: (response) => {
            if (response && response.response && response.response.length > 0) {
              this.setState({ previewPreview: response.response[0] });
            }
            return response.response;
          },
          error: console.error
        });
    }
  }

  downloadFile = (file) => {
    const { product } = this.state;

    apiDriver
      .get(
        `${config.api.orders}/pl/OrderProducts/${product.id}/${this.fileTypeNamespace(file.type)}/${file.objectName}/Download`
      )
      .subscribe({
        next: (response) => {
          const link = document.createElement("a");
          link.href = response.response;
          link.setAttribute("download", file.objectName);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          return response.response;
        },
        error: console.error
      });
  }

  removeFile = (file) => {
    const { product } = this.state;
    apiDriver
      .remove(`${config.api.orders}/pl/OrderProducts/${product.id}/${this.fileTypeNamespace(file.type)}/${file.objectName}`)
      .subscribe(() => {
        switch (file.type) {
          case 1:
            this.setState({ materials: this.state.materials.filter(f => f.objectName !== file.objectName) });
            break;
          case 2:
            this.setState({ projects: this.state.projects.filter(f => f.objectName !== file.objectName) });
            break;
          case 3:
            this.setState({ previews: this.state.previews.filter(f => f.objectName !== file.objectName) });
            break;
          default:
            break;
        }
      });
  }

  renderFile = (file) => {
    return (
      <tr key={file.objectName}>
        <td><i className="fas fa-times-circle text-danger" style={{ cursor: "pointer" }} onClick={() => this.removeFile(file)}></i></td>
        <td onClick={() => this.downloadFile(file)} style={{ cursor: "pointer" }}>{file.objectName}</td>
        <td>{this.fileTypeTitle(file.type)}</td>
        <td>{this.humanReadableFileSize(file.stat.size)}</td>
      </tr>
    );
  }


  onUploadComplete = () => {
    this.loadProjects();
  }

  renderFilesList = (types) => {
    const { materials, projects, previews } = this.state;

    return (
      <div>
        <Table className="table-sm">
          <thead>
            <tr className="text-uppercase text-xs text-muted">
              <th></th>
              <th>Plik</th>
              <th>Rodzaj</th>
              <th>Waga</th>
            </tr>
          </thead>
          <tbody>
            {(types === undefined || types.includes('materials')) && materials?.map(this.renderFile)}
            {(types === undefined || types.includes('projects')) && projects?.map(this.renderFile)}
            {(types === undefined || types.includes('previews')) && previews?.map(this.renderFile)}
          </tbody>
        </Table>
      </div>
    )
  }

  renderUploader = (type) => {
    const { product } = this.state;

    return (
      <>
        Wgraj {this.fileTypeTitle(type)}:
        <Uploader
          id={product.id}
          name={product.id}
          url={`${config.api.orders}/pl/OrderProducts/${product.id}/${this.fileTypeNamespace(type)}/FILENAME`}
          isPresigned={true}
          onUploadComplete={this.onUploadComplete}
        />
      </>
    )
  }

  renderStatusLabel = (status) => {
    switch (status?.tag) {
      case 4:
        return (
          <Label className="bg-primary text-white px-3 py-1 rounded">
            Weryfikacja
          </Label>
        );
      case 5:
        return (
          <Label className="bg-warning text-white px-3 py-1 rounded">
            Akceptacja
          </Label>
        );
      case 6:
      case 7:
      case 8:
      case 9:
        return (
          <Label className="bg-success text-white px-3 py-1 rounded">
            W druku
          </Label>
        );
      default:
        return (
          <Label className="bg-danger text-white px-3 py-1 rounded">
            {status?.translations[0]?.title || "Koszyk"}
          </Label>
        );
    }
  }

  renderCardBody = () => {
    const { status } = this.state.product;
    switch (status?.tag) {
      case 4:
        return this.renderFileInVerification();
      case 5:
        return this.renderFileInAcceptation();
      case 6:
      case 7:
      case 8:
      case 9:
        return this.renderFileInPrint();
      default:
        return this.renderFileInCart();
    }
  }

  renderFileHeader = () => {
    const { product } = this.state;
    return (
      <CardHeader>
        <CardTitle>
          <div className="clearfix">
            <div className="float-left">
              {product.number || product.id}
            </div>
            <div className="float-right">
              {this.renderStatusLabel(product.status)}
            </div>
          </div>
        </CardTitle>
        <CardSubtitle>
          <div className="clearfix text-muted text-sm">
            <div className="float-left">
              {product.title || product.product?.translations?.find(() => true)?.title || 'Niestandardowy'}
            </div>
            <div className="float-right">
              {product.personalization?.amount} szt.
            </div>
          </div>
          <p className="text-xs text-muted"><ProductPersonalization product={product} inline /></p>
        </CardSubtitle>
      </CardHeader>
    );
  }

  saveProduct = () => {
    const p = this.state.product;
    const { product, priceObj, ...pureProduct } = p;
    apiDriver
      .patch(`${config.api.orders}/pl/OrderProducts/${p.id}`, { ...pureProduct })
      .subscribe({
        next: () => console.log("Saved"),
        complete: () => this.props.loadOrderProduct()
      });
  }

  saveNote = () => {
    const { product } = this.state;
    const note = this.firstNote();
    delete note.product;
    delete note.order;

    if (!note?.id) {
      apiDriver
        .post(`${config.api.orders}/pl/OrderNotes`, { ...note, orderId: product.orderId, productId: product.id })
        .subscribe(() => console.log("Created"));
    } else {
      apiDriver
        .patch(`${config.api.orders}/pl/OrderNotes/${note.id}`, { ...note })
        .subscribe(() => console.log("Saved"));
    }
  }

  saveVerification = () => {
    const { verificationNote, verification, product } = this.state;
    apiDriver
      .post(`${config.api.orders}/pl/OrderNotes`,
        {
          content: verificationNote,
          orderNoteVerificationTemplates: verification?.map((t) => ({ verificationTemplateId: t })) || [],
          orderId: product.orderId,
          productId: product.id
        }
      )
      .subscribe(() => console.log("Created"));
  }

  onChangeProductEvent = (e) => {
    const { name, value } = e.target;
    this.onChangeProduct(name, value);
  }

  onChangeProduct = (name, value) => {
    const tmpProduct = { ...this.state.product };
    _.set(tmpProduct, name, value);
    this.setState({ product: tmpProduct });
  }

  onChangeNoteEvent = (e) => {
    const { name, value } = e.target;
    this.onChangeNote(name, value);
  }

  onChangeNote = (name, value) => {
    const { notes } = this.state;
    const firstNote = this.firstNote() || {};
    _.set(firstNote, name, value);
    this.setState({ notes: [...notes.map(n => n.id === firstNote.id ? firstNote : n)] });
  }

  onChangeVerificationNoteEvent = (e) => {
    const { value } = e.target;
    this.onChangeVerificationNote(value);
  }

  onChangeVerificationNote = (value) => {
    this.setState({ verificationNote: value })
  }

  onChangeVerificationTemplateEvent = (e) => {
    const { value, checked } = e.target;
    this.onChangeVerificationTemplate(value, checked);
  }

  onChangeVerificationTemplate = (id, checked) => {
    const verificationTemplates = this.state.verification ? [...this.state.verification] : [];
    if (checked) {
      if (!verificationTemplates.some(t => t.id === id)) {
        this.setState({ verification: [...verificationTemplates, id] });
      }
    } else {
      if (verificationTemplates.some(t => t.id === id)) {
        this.setState({ verification: [...verificationTemplates].filter(t => t.id !== id) });
      }
    }
  }

  firstNote = () => {
    const { notes } = this.state;
    return notes?.sort((a, b) => a.created.localeCompare(b.created)).find(a => true);
  }

  verificationNote = () => {
    const { notes } = this.state;
    const lastProject = this.lastProject();
    if (!lastProject) return;
    if (notes?.length == 0) return;
    const n = notes?.filter(a => a.created.localeCompare(lastProject.stat.lastModified) > 0)
      .sort((a, b) => a.created.localeCompare(b.created))?.find(a => true);
    return n;
  }

  onSubmitInCartForm = () => {
    const { note } = this.state;
    if (note?.content) {
      this.saveNote();
    }
    this.saveProduct();
  }

  onSubmitInCartForm = () => {
    const { note } = this.state;
    if (note?.content) {
      this.saveNote();
    }
    this.saveProduct();
  }

  setFileStatus = (objectType, objectKey, accepted, note) => {
    const { product, projects } = this.state;
    apiDriver
      .post(`${config.api.orders}/pl/OrderProducts/${product.id}/${objectType}s/${objectKey}/Review`, { accepted, note })
      .subscribe({
        next: (response) => {
          if (response.response) {
            this.setState({ projects: [...projects.map(p => p.objectName === objectKey ? { ...p, accepted, note } : { ...p })] });
          }
        },
        error: (error) => {
          console.log(error);
        },
        complete: () => this.props.loadOrderProduct()
      });
  }

  onSubmitInAcceptationForm = (verdict) => {
    const lastPreview = this.lastPreview();

    this.setFileStatus('Preview', lastPreview.objectName, this.verdictToAccepted(verdict), "");
    this.saveVerification();
  }

  onSubmitInVerificationForm = (verdict) => {
    const { verificationNote } = this.state;
    const lastProject = this.lastProject();
    const lastPreview = this.lastPreview();

    this.setFileStatus('Project', lastProject.objectName, this.verdictToAccepted(verdict), verificationNote || "");
    this.saveVerification();
    if (verdict === 2) {
      this.setFileStatus('Preview', lastPreview.objectName, this.verdictToAccepted(verdict), verificationNote || "");
    }
  }
  verdictToAccepted = (v) => {
    switch (v) {
      case 0:
        return false;
      case 1:
      case 2:
        return true;
      default:
        return null;
    }
  }

  renderFileInPrint = () => {
    const verificationNote = this.verificationNote();
    return (
      <>
        <CardBody>
          {this.renderFilePreview()}
        </CardBody>
        <CardFooter>
          {verificationNote?.content}
          {verificationNote?.verificationTemplates?.map(template => <div className={this.getVerificationTemplateClass(template)}>{localizer(template).currentTranslation.content}</div>)}
          <hr />
          <p className="text-xl text-center text-success">Plik zaakceptowany</p>
        </CardFooter>
      </>
    )
  }

  renderFileInAcceptation = () => {
    return (
      <>
        <CardBody>
          {this.renderFilePreview()}
        </CardBody>
        <CardFooter>
          <Row>
            <Col md={6}>
            </Col>
            <Col md={6}>
              <Button color="primary" block onClick={() => this.onSubmitInAcceptationForm(1)}>Prześlij do druku</Button>
            </Col>
          </Row>
        </CardFooter>
      </>
    )
  }

  lastProject = () => {
    const { projects } = this.state;
    return projects?.sort((a, b) => a.stat.lastModified > b.stat.lastModified).find(a => true);
  }

  lastPreview = () => {
    const { previews } = this.state;
    return previews?.sort((a, b) => a.stat.lastModified > b.stat.lastModified).find(a => true);
  }

  latestPreviews = () => {
    const { previews } = this.state;
    const lastProject = this.lastProject();
    return previews?.filter(a => a.stat.lastModified > lastProject.stat.lastModified);
  }

  getVerificationTemplateClass = (template) => {
    switch (template.type) {
      case 0:
        return 'text-info';
      case 1:
        return 'text-success';
      case 2:
        return 'text-warning';
      case 3:
        return 'text-danger';
      default:
        return 'text-info';
    }
  }

  renderFileInVerification = () => {
    const { product, verificationNote, verification } = this.state;
    const { verificationTemplates } = this.props;

    return (
      <>
        <CardBody>
          {this.renderFilePreview()}
        </CardBody>
        <CardFooter>
          {verificationTemplates?.map(template => (
            <FormGroup key={template.id}>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  id={`product-${product.id}-template-${template.id}`}
                  type="checkbox"
                  name="templates[]"
                  value={template.id}
                  checked={verification?.includes(template.id)}
                  onChange={this.onChangeVerificationTemplateEvent}
                />
                <label
                  className="form-check-label"
                  htmlFor={`product-${product.id}-template-${template.id}`}
                >
                  <span className={this.getVerificationTemplateClass(template)}>{localizer(template).currentTranslation.content}</span>
                </label>
              </div>
            </FormGroup>
          ))}
          <FormGroup>
            <Input
              type="textarea"
              name="description"
              placeholder="Dodatkowe uwagi"
              rows="5"
              value={verificationNote}
              onChange={this.onChangeVerificationNoteEvent}
            />
          </FormGroup>
          <div>
            {this.renderFilesList(['previews'])}
          </div>
          <div className="pt-2 pb-5">
            {this.renderUploader(3)}
          </div>
          <Row>
            <Col md={4}>
              <Button color="danger" block onClick={() => this.onSubmitInVerificationForm(0)}>Odrzuć</Button>
            </Col>
            <Col md={4}>
              <Button color="success" block disabled={this.latestPreviews().length === 0} onClick={() => this.onSubmitInVerificationForm(1)}>Akceptuj</Button>
            </Col>
            <Col md={4}>
              <Button color="primary" block disabled={this.latestPreviews().length === 0} onClick={() => this.onSubmitInVerificationForm(2)}>Do druku</Button>
            </Col>
          </Row>
        </CardFooter>
      </>
    )
  }

  renderFilePreview = () => {
    const { product, projectPreview } = this.state;
    const project = this.lastProject();
    const firstNote = this.firstNote();

    const getChecking = (checking) => {
      switch (checking) {
        case "None":
          return "Brak";
        case "Standard":
          return "Podstawowe";
        case "Extended":
          return "Rozszerzone";
        default:
          return "Brak";
      }
    };

    return (
      <>
        {projectPreview && <img src={projectPreview} className="relative w-100" />}
        {project && <Button color="info" block onClick={() => this.downloadFile(project)}>Pobierz {project.objectName} ({this.humanReadableFileSize(project.stat.size)})</Button>}
        <Row className="my-3 text-sm text-muted">
          <Col md={6}>
            Sprawdzanie:
          </Col>
          <Col md={6} className="text-right">
            {getChecking(product?.personalization?.checking)}
          </Col>
        </Row>
        <div>
          {firstNote?.content}
        </div>
      </>
    );
  }

  renderFileInCart = () => {
    const { product, note } = this.state;
    return (
      <>
        <CardBody>
          {this.renderFilesList()}
          {this.renderUploader(1)}
          {this.renderUploader(2)}
          {this.renderUploader(3)}
        </CardBody>
        <CardFooter>
          <FormGroup>
            <Label for="checking">Sprawdzanie</Label>
            <Input type="select" id="checking" name="personalization.checking" value={product.personalization?.checking} onChange={this.onChangeProductEvent}>
              <option value="None">Brak</option>
              <option value="Basic">Podstawowe</option>
              <option value="Extended">Rozszerzone</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Input
              type="textarea"
              name="content"
              placeholder="Dodatkowe uwagi"
              rows="5"
              value={note?.content}
              onChange={this.onChangeNoteEvent}
            />
          </FormGroup>
          <Button type="button" color="primary" block onClick={this.onSubmitInCartForm}>Zatwierdź</Button>
        </CardFooter>
      </>
    );
  }

  render = () => {
    const { product } = this.state;

    if (!product) {
      return (
        <Spinner />
      );
    }

    return (
      <Col className="col-lg-4 col-md-12 p-1">
        <Card>
          {this.renderFileHeader()}
          {this.renderCardBody()}
        </Card>
      </Col>
    )
  }

  fileTypeNamespace = (t) => {
    switch (t) {
      case 1:
        return 'Materials';
      case 2:
        return 'Projects';
      case 3:
        return 'Previews';
      default:
        return '';
    }
  }

  fileTypeTitle = (t) => {
    switch (t) {
      case 1:
        return 'Materiały';
      case 2:
        return 'Projekt';
      case 3:
        return 'Plik poglądowy';
      default:
        return '';
    }
  }

  humanReadableFileSize = (size) => {
    const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return (
      (size / Math.pow(1024, i)).toFixed(2) * 1 +
      " " +
      ["B", "kB", "MB", "GB", "TB"][i]
    );
  };
}

export default class OrderFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verificationTemplates: []
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    this.loadVerificationTemplates();
  };

  loadVerificationTemplates = () => {
    apiDriver
      .get(`${config.api.orders}/pl/VerificationTemplates?Take=1000`)
      .subscribe({
        next: (response) => {
          if (response.response?.length > 0) {
            this.setState({ verificationTemplates: response.response });
          }
        },
        error: (error) => {
          console.log(error);
        },
      });
  }


  render = () => {
    const { order } = this.props;
    const { verificationTemplates } = this.state;
    return (
      <React.Fragment>
        <Row>
          {order.products?.map((product, index) => <OrderProductFile key={index} product={product} verificationTemplates={verificationTemplates} loadOrderProduct={() => setTimeout(() => this.props.loadOrderProduct(product.id), 3000)} />)}
        </Row>
      </React.Fragment>
    );
  };
}
