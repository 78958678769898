import React, { useEffect, useRef, useState } from "react";

import { Input } from "reactstrap";

export default function ValidatedInput(props) {
  const { onChange, onBlur, value, required, triggerValidate, toValidate, setToValidate, ...inheritedProps } = props;
  const [ internalValue, setInternalValue ] = useState(value || props.defaultValue || "");
  const [ isValid, setIsValid ] = useState(null);
  const input = useRef();

  const onBlurMiddleware = (event) => {
    if (isValid === false) {
      setInternalValue(value);
      event.target.reportValidity();
    }
    if(internalValue === "") {
      if(required) {
        setIsValid(false);
      } else {
        input.current.dispatchEvent(new Event("change"));
        setIsValid(null);
      }
    }
    if(onBlur) {
      onBlur(event);
    }
  };

  const onChangeMiddleware = (event) => {
    const val = event.target.value;
    setInternalValue(val);

    if (!val) {
      setIsValid(null);
      return;
    }

    if(!event.target.checkValidity()) {
      setIsValid(false);
      return;
    }

    setIsValid(true);

    if(onChange) {
      onChange(event);
    }
  };

  const clear = () => {
    setIsValid(null);
    input.current.dispatchEvent(new Event('change'));
  }

  useEffect(() => {
    if(toValidate) {
      if(!input.current.checkValidity()) {
        input.current.reportValidity();
        clear();
        if(setToValidate) {
          setToValidate(false);
        }
        return;
      }
    }
    if(setToValidate) {
      setToValidate(false);
    }
  }, [ toValidate ])

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <Input
      innerRef={input}
      value={internalValue}
      onChange={onChangeMiddleware}
      onBlur={onBlurMiddleware}
      valid={isValid === true}
      invalid={isValid === false}
      required={required}
      {...inheritedProps}
    />
  );
}
