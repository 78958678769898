import React from "react";
import {
  Button,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { API_URL as USERS_API_URL } from "stores/iam/users/epics";
import { API_URL as ORDERS_API_URL } from "stores/orders/orders/epics";
import { API_URL as SHIPPING_METHODS_API_URL } from "stores/orders/shippingMethods/epics";
import { API_URL as SHIPPING_ADDRESSES_API_URL } from "stores/orders/shippingAddresses/epics";
import { API_URL as SENDER_ADDRESSES_API_URL } from "stores/orders/senderAddresses/epics";

import * as shippingMethodsActions from "stores/orders/shippingMethods/actions";
import AsyncSelector from "./AsyncSelector";
import apiDriver from "stores/api.driver";
import GLSForm from "./GLSForm";
import localizer from "stores/localizer";

class DeliveryModal extends React.Component {
  constructor(props) {
    super(props);

    let shipping = props.shipping || this.defaultModel();
    this.state = {
      shipping: shipping,
      shippingMethods: [],
      shippingMethodsLoaded: false,
      users: [],
      usersLoaded: false,
      statuses: [],
      statusesLoaded: false,
      orders: [],
      orderLoaded: false,
    };
  }

  toggle = () => this.props.toggle();
  parseValue = (target) => this.props.parseValue(target);

  componentDidMount = () => {
    this.props.listShippingMethods();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.shippingMethods !== this.props.shippingMethods) {
      this.setState({
        shippingMethods: this.props.shippingMethods,
        shippingMethodsLoaded: false,
      });
    }
    if (prevState.shipping.methodId !== this.state.shipping.methodId) {
      if (prevState.shipping.methodId)
        this.setState({ shipping: { ...this.state.shipping, statusId: "" } });
      this.filterStatuses();
    }
    if (prevState.shipping.userId !== this.state.shipping.userId) {
      if (prevState.shipping.userId)
        this.setState({
          shipping: {
            ...this.state.shipping,
            shippingAddressId: "",
            senderAddressId: "",
          },
        });
      this.filterStatuses();
    }
  }

  defaultModel = () => {
    const { user, order, method } = this.props;

    return {
      externalId: "",

      userId: user ? user.id : order ? order.userId : "",
      orderId: order ? order.id : "",
      methodId: method ? method.id : order ? order.shippingMethodId : "",
      shippingAddressId: order ? order.shippingAddressId : "",
      senderAddressId: order ? order.senderAddressId : "",
      statusId: "",

      trackingNumber: "",
      trackingLink: "",
    };
  };

  filterMethods = (inputValue) => {
    const { shippingMethods } = this.state;
    return new Promise((resolve, reject) => {
      let result = shippingMethods.filter((m) => m.isShippingAddressRequired);
      if (inputValue) {
        result = result.filter((m) =>
          m.currentTranslation?.title
            .toLowerCase()
            .includes(inputValue.toLowerCase()),
        );
      }
      result = result.map(this.renderMethod);
      resolve(result);
    });
  };
  renderMethod = (method) => ({
    value: method.id,
    label: method.currentTranslation?.title,
  });

  fetchUser = (id) =>
    apiDriver
      .get(USERS_API_URL + id)
      .toPromise()
      .then((result) => result.response)
      .then((user) => this.renderUser(user));
  filterUsers = (inputValue) => {
    return apiDriver
      .get(USERS_API_URL + "?Take=10&SearchText=" + inputValue)
      .toPromise()
      .then((result) => result.response)
      .then((users) => users.map(this.renderUser));
  };
  renderUser = (user) => ({ value: user.id, label: user.username });

  fetchOrder = (id) =>
    apiDriver
      .get(ORDERS_API_URL + id)
      .toPromise()
      .then((result) => result.response)
      .then((order) => this.renderOrder(order));
  filterOrders = (inputValue) => {
    return apiDriver
      .get(ORDERS_API_URL + "?Take=10&SearchText=" + inputValue)
      .toPromise()
      .then((result) => result.response)
      .then((orders) => orders.map(this.renderOrder));
  };
  renderOrder = (order) => ({
    value: order.id,
    label: order.number || order.id,
    methodId: order.shippingMethodId,
    userId: order.userId,
  });

  filterStatuses = (inputValue, methodId) => {
    const { shipping } = this.state;
    return apiDriver
      .get(SHIPPING_METHODS_API_URL + (methodId || shipping.methodId))
      .toPromise()
      .then((result) => result.response)
      .then((method) =>
        method.statuses.filter((status) =>
          inputValue
            ? status.currentTranslation?.title.includes(inputValue)
            : true,
        ),
      )
      .then((statuses) => {
        this.setState({ statuses: statuses });
        return statuses;
      })
      .then((statuses) => statuses.map(this.renderStatus));
  };
  renderStatus = (status) => ({
    value: status.id,
    label: localizer(status)?.currentTranslation?.title,
  });

  fetchShippingAddress = (id) =>
    apiDriver
      .get(SHIPPING_ADDRESSES_API_URL + id)
      .toPromise()
      .then((result) => result.response)
      .then((order) => this.renderShippingAddress(order));
  filterShippingAddresses = (inputValue) => {
    const { shipping } = this.state;
    return apiDriver
      .get(
        SHIPPING_ADDRESSES_API_URL +
        "?UserId= " +
        shipping.userId +
        "&SearchText=" +
        inputValue,
      )
      .toPromise()
      .then((result) => result.response)
      .then((addresses) => addresses.map(this.renderShippingAddress));
  };
  renderShippingAddress = (address) => ({
    value: address.id,
    label: this.shippingAddressLabel(address),
  });
  shippingAddressLabel = (address) => {
    return (
      <div>
        <div>{this.abstractAddressString(address)}</div>
        <div className="text-muted">
          <small>{this.abstractContactString(address)}</small>
        </div>
      </div>
    );
  };

  abstractAddressString = (address) =>
    `${address.line1}${address.line2 ? " " + address.line2 : ""}, ${address.postalCode
    } ${address.city}`;
  abstractContactString = (address) => {
    let contactString = [address.person, address.phone, address.email].filter(
      (a) => a,
    );
    return contactString.length > 0 ? "(" + contactString.join(", ") + ")" : "";
  };



  fetchSenderAddress = (id) =>
    apiDriver
      .get(SENDER_ADDRESSES_API_URL + id)
      .toPromise()
      .then((result) => result.response)
      .then((order) => this.renderSenderAddress(order));
  filterSenderAddresses = (inputValue) => {
    const { shipping } = this.state;
    return apiDriver
      .get(
        SENDER_ADDRESSES_API_URL +
        "?UserId= " +
        shipping.userId +
        "&SearchText=" +
        inputValue,
      )
      .toPromise()
      .then((result) => result.response)
      .then((addresses) => addresses.map(this.renderSenderAddress));
  };
  renderSenderAddress = (address) => ({
    value: address.id,
    label: this.senderAddressLabel(address),
  });
  senderAddressLabel = (address) => {
    return (
      <div>
        <div>{this.abstractAddressString(address)}</div>
        <div className="text-muted">
          <small>{this.abstractContactString(address)}</small>
        </div>
      </div>
    );
  };

  submit = () => {
    let {
      method,
      status,
      order,
      products,
      shippingAddress,
      senderAddress,
      collectPoint,
      ...shipping
    } = this.state.shipping;
    const { update, create } = this.props;
    if (!shipping.statusId) delete shipping.statusId;
    if (shipping.id) {
      update(shipping);
    } else {
      create(shipping);
    }
    this.toggle();
  };

  changeShippingSpecialData = (e) => {
    let { shipping } = this.state;
    const value = this.parseValue(e.target);

    this.setState({
      shipping: {
        ...shipping,
        data: { ...shipping.data, [value.name]: value.value },
      },
    });
  };

  changeShippingData = (e) => {
    let { shipping } = this.state;
    const value = this.parseValue(e.target);

    if (value.value) {
      switch (value.name) {
        case "orderId":
          shipping = { ...shipping };
          shipping.userId = shipping.userId || e.target.rawValue.userId;
          shipping.methodId = shipping.methodId || e.target.rawValue.methodId;
          this.filterStatuses("", shipping.methodId);
          break;
        default:
          break;
      }
    }

    this.setState({ shipping: { ...shipping, [value.name]: value.value } });
  };

  renderCancelButton = () => (
    <Button color="light" onClick={() => this.toggle()}>
      Anuluj
    </Button>
  );

  renderSubmitButton = () => {
    const { shipping } = this.state;
    return (
      <Button color="primary" onClick={() => this.submit()}>
        {shipping.id ? "Zapisz" : "Dodaj"}
      </Button>
    );
  };

  renderContent = () => {
    const { shipping, shippingMethods, users, statuses, orders } = this.state;

    const method = shippingMethods.find((m) => m.id === shipping.methodId);

    return (
      <Form>
        <Row>
          <Col md="6">
            <Row>
              <Col md="4">
                <Label for="methodId">Zamówienie</Label>
              </Col>
              <Col md="8">
                <FormGroup>
                  <InputGroup>
                    <AsyncSelector
                      title="Zamówienie"
                      name="orderId"
                      placeholder="Szukaj..."
                      options={orders.map(this.renderOrder)}
                      value={shipping.orderId}
                      onChange={this.changeShippingData}
                      onSearch={this.filterOrders}
                      fetchNotFound={this.fetchOrder}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="4">
                <Label for="userId">Uzytkownik</Label>
              </Col>
              <Col md="8">
                <FormGroup>
                  <InputGroup>
                    <AsyncSelector
                      title="Uzytkownik"
                      name="userId"
                      placeholder="Szukaj..."
                      options={users.map(this.renderUser)}
                      value={shipping.userId}
                      onChange={this.changeShippingData}
                      onSearch={this.filterUsers}
                      fetchNotFound={this.fetchUser}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="4">
                <Label for="methodId">Metoda dostawy</Label>
              </Col>
              <Col md="8">
                <FormGroup>
                  <InputGroup>
                    <AsyncSelector
                      title="Metoda dostawy"
                      name="methodId"
                      placeholder="Szukaj..."
                      options={shippingMethods.map(this.renderMethod)}
                      value={shipping.methodId}
                      onChange={this.changeShippingData}
                      onSearch={this.filterMethods}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="4">
                <Label for="statusId">Status</Label>
              </Col>
              <Col md="8">
                <FormGroup>
                  <InputGroup>
                    <AsyncSelector
                      title="Status dostawy"
                      name="statusId"
                      placeholder="Szukaj..."
                      allowNull={true}
                      options={statuses.map(this.renderStatus)}
                      value={shipping.statusId}
                      onChange={this.changeShippingData}
                      onSearch={this.filterStatuses}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="4">
                <Label for="shippingAddressId">Adres dostawy</Label>
              </Col>
              <Col md="8">
                <FormGroup>
                  <InputGroup>
                    <AsyncSelector
                      title="Adres dostawy"
                      name="shippingAddressId"
                      placeholder="Szukaj..."
                      allowNull={true}
                      options={statuses.map(this.renderShippingAddress) || [this.renderShippingAddress(shipping.shippingAddress)]}
                      value={shipping.shippingAddressId}
                      onChange={this.changeShippingData}
                      onSearch={this.filterShippingAddresses}
                      fetchNotFound={this.fetchShippingAddress}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="4">
                <Label for="senderAddressId">Adres nadawcy</Label>
              </Col>
              <Col md="8">
                <FormGroup>
                  <InputGroup>
                    <AsyncSelector
                      title="Adres nadawcy"
                      name="senderAddressId"
                      placeholder="Szukaj..."
                      allowNull={true}
                      options={statuses?.map(this.renderSenderAddress) || [this.renderSenderAddress(shipping.senderAddress)]}
                      value={shipping.senderAddressId}
                      onChange={this.changeShippingData}
                      onSearch={this.filterSenderAddresses}
                      fetchNotFound={this.fetchSenderAddress}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md="6">
            <Row>
              <Col md="4">
                <Label for="externalId">ID zewnętrzne</Label>
              </Col>
              <Col md="8">
                <FormGroup>
                  <InputGroup>
                    <Input
                      type="text"
                      id="externalId"
                      name="externalId"
                      value={shipping.externalId}
                      onChange={this.changeShippingData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="4">
                <Label for="trackingNumber">List przewozowy</Label>
              </Col>
              <Col md="8">
                <FormGroup>
                  <InputGroup>
                    <Input
                      type="text"
                      id="trackingNumber"
                      name="trackingNumber"
                      value={shipping.trackingNumber}
                      onChange={this.changeShippingData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="4">
                <Label for="trackingLink">Link trackingu</Label>
              </Col>
              <Col md="8">
                <FormGroup>
                  <InputGroup>
                    <Input
                      type="text"
                      id="trackingLink"
                      name="trackingLink"
                      value={shipping.trackingLink}
                      onChange={this.changeShippingData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              {method != null ? (
                method.integration === "GLS" ? (
                  <GLSForm
                    parseValue={this.parseValue}
                    shipping={shipping}
                    changeShippingData={this.changeShippingData}
                    changeShippingSpecialData={this.changeShippingSpecialData}
                  />
                ) : (
                  <React.Fragment />
                )
              ) : (
                <React.Fragment />
              )}
            </Row>
          </Col>
        </Row>
      </Form>
    );
  };

  render = () => {
    const { isOpen, title } = this.props;

    return (
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={this.toggle}
        size="xl"
      >
        <ModalHeader>
          <CardTitle>{title}</CardTitle>
        </ModalHeader>
        <ModalBody>{this.renderContent()}</ModalBody>
        <ModalFooter>
          {this.renderCancelButton()}
          {this.renderSubmitButton()}
        </ModalFooter>
      </Modal>
    );
  };
}

function mapStateToProps(state) {
  return {
    loading: state.orders.loading,
    auth: state.auth.user,
    session: state.session,
    shippings: state.shippings.list,
    shippingMethods: state.shippingMethods.list,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    listShippingMethods: () => dispatch(shippingMethodsActions.list(0, 1000)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeliveryModal),
);
