import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Input, InputGroup, InputGroupAddon } from 'reactstrap';

const SearchBar = ({ onSearch, delay, ...props }) => {
    const [ value, setValue ] = useState('');
    const [ timer, setTimer ] = useState(null);
    const onChange = (event) => setValue(event.target.value);
    const onSubmit = (event) => {
        onSearch(value);
        if(event) {
            event.preventDefault();
        }
    }

    useEffect(() => {
        if(timer != null) {
            clearTimeout(timer);
        }
        //setTimer(setTimeout(onSubmit, delay || 250));
    }, [ value ]);

    return (
        <Form onSubmit={onSubmit}>
            <FormGroup>
                <InputGroup>
                    <Input type="text" value={value} onChange={onChange} />
                    <InputGroupAddon addonType="append">
                        <Button type="submit">
                            <i className="fas fa-search"></i>
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            </FormGroup>
        </Form>
    );
};

export default SearchBar;