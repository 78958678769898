import React from "react";

function Colors() {
  return [
    "1+0",
    "1+1",
    "4+0",
    "4+1",
    "4+4",
    "2+0",
    "2+1",
    "5+0",
    "5+1",
    "5+4",
    "5+5",
  ];
}

export default function ProductPersonalization(props) {
  const { product, full, includePrices, includeWeight, inline } = props;

  const getChecking = (checking) => {
    switch (checking) {
      case 0:
        return "Brak";
      case 1:
        return "Podstawowe";
      case 2:
        return "Rozszerzone";
      default:
        return "Brak";
    }
  };

  const getPersonalization = () => {
    let result = [];

    if (full) {
      result.push(`
        Nakład:
        ${new Intl.NumberFormat(undefined, { maximumFractionDigits: 0, }).format(product?.personalization?.amount)}
        szt.
      `);
    }

    if (product?.personalization?.format) {
      const format = product.personalization.format;
      result.push(
        `Format: ${format.x}
    x ${format.y}
    mm`,
      );
    }
    if (product?.personalization?.pages) {
      const pages = product.personalization.pages;
      if (pages.inside <= 2 && pages.outside === 0) {
        // do nothing
      }
      else if (pages.inside && pages.outside) {
        result.push(
          `Strony: ${pages.inside} wewnętrzne, ${pages.outside} zewnętrzne`,
        );
      }
      else if (pages.inside > 1) {
        result.push(
          `Strony: ${pages.inside} wewnętrzne`,
        );
      }
      else if (pages.outside) {
        result.push(
          `Strony: ${pages.outside} zewnętrzne`,
        );
      }
    }
    if (product?.personalization?.optionsLoaded) {
      product.personalization.optionsLoaded.map((option) => {
        const optionTranslation = option.translations[0];
        const attribute = option.attribute;
        const attributeTranslation = attribute.translations[0];
        result.push(
          `${attributeTranslation?.title}: ${optionTranslation?.title}`,

        );
        return option;
      });
    }
    if (product?.personalization?.additivesLoaded) {
      product.personalization.additivesLoaded.map((additive) => {
        const additiveTranslation = additive.translations[0];
        const group = additive.group;
        if (group) {
          const groupTranslation = group.translations[0];
          result.push(
            `${groupTranslation?.title}: ${additiveTranslation?.title}`,

          );
        }
        else {
          result.push(additiveTranslation?.title);
        }
        return additive;
      });
    }
    if (product?.personalization?.colorProfiles?.length > 1) {
      const colorProfiles = product.personalization.colorProfiles
      const altColorProfiles = colorProfiles.filter(cp => cp.name.includes('alt.'));
      if (altColorProfiles.length) {
        result = [...result, ...colorProfiles.map(cp => {
          if (cp.pages.inside > 0) return `${Colors()[cp.color]}: ${cp.pages.inside} strony wewnętrzne`
          if (cp.pages.outside > 0) return `${Colors()[cp.color]}: ${cp.pages.outside} strony zewnętrzne`
        })];
      }
    }

    if (full) {
      result.push(`Sprawdzanie: ${getChecking(product?.personalization?.checking)}`);
    }

    if (includeWeight) {
      result.push(`Waga: ${product.weight} kg`);
    }

    if (includePrices) {
      result.push(`
        Cena netto:
        ${new Intl.NumberFormat(undefined, {
        style: "currency",
        currency: "PLN",
      }).format(product.priceObj.netCost)}
      `);
      result.push(`
        Cena brutto:
        ${new Intl.NumberFormat(undefined, {
        style: "currency",
        currency: "PLN",
      }).format(product.priceObj.grossCost)}
      `);
    }

    return result.filter(r => r).map((r, i) => (
      <React.Fragment key={i}>
        {r}
        {inline ? ", " : <br />}
      </React.Fragment >
    ));
  };

  return < span >{product?.personalization && getPersonalization()}</ span >;
}
